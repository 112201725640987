body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-moz-selection {
  color: rgb(5, 5, 124);
  background: #79E0DB;
}

::selection {
  color: rgb(5, 5, 124);
  background: #79E0DB;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e7e7e7; 
}

::-webkit-scrollbar-thumb {
  background: #12047D; 
  border: 0px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.reading-progress-bar {
  position: sticky;
  height: 5px;
  top: 0;
  background-color: #12047D;
  border-radius: 8px;
}

#mapBox div:first-child {
  position: relative !important;
}

.MuiStepIcon-root {
  color: #E7813D !important;
}